<template>
    <main>
        <div class="card">
            <div
                style="border-radius: 200px; height: 200px; width: 200px; margin: 0 auto"
                :class="result.success ? 'bg-[#F8FAF5]' : 'bg-red-100'"
            >
                <i class="checkmark" :class="result.success ? 'text-[#88B04B]' : 'text-red-400'">{{
                    result.success ? '✓' : 'X'
                }}</i>
            </div>
            <h1 :class="result.success ? 'text-[#88B04B]' : 'text-red-400'">
                {{ result.success ? $lang.pages.unsubscribe.success : $lang.pages.unsubscribe.ops }}
            </h1>
            <p>{{ result.success ? $lang.pages.unsubscribe.congrats : $lang.pages.unsubscribe.error }}</p>
        </div>
    </main>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
const { buildHeaders, baseURL, endpoints } = useApiConfig()

type SubscribeResponse =
    | {
          error: true
          data: {
              email: string
          }
          feedback: 'params_validation_failed'
      }
    | {
          error: false
          data: {
              message: string
          }
          feedback: 'data_success'
      }
    | {
          error: true
          data: {
              message: string
          }
          feedback: 'error_mail'
      }

const { $lang } = useNuxtApp()
const Route = useRoute()
const AuthStore = useAuthStore()
const config = useRuntimeConfig()

const { data: responseData, error } = await useAsyncData('subscribe-response-data', () =>
    $fetch<SubscribeResponse>(endpoints.general.newsletter.unsubscribe, {
        baseURL,
        headers: buildHeaders(AuthStore.SessionToken),
        body: {
            email: Route.query.email,
        },
        method: 'POST',
        onResponse() {
            if (config.public.variant === 'megadescuentos') {
                AuthStore.updateActivitiesCount('notifications')
            }
        },
    }).catch((e) => e.data),
)
if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: 400,
        message: 'Error data',
    })
}

const result = reactive({
    success: false,
    existing: false,
})

if (responseData.value.feedback === 'data_success') {
    result.success = true
} else {
    result.success = false
}

const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.subscribe.title
const description = $lang.pages.subscribe.description

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>
<style lang="postcss" scoped>
main {
    @apply container mt-10 flex pt-5 text-center sm:justify-center md:items-center md:pb-20 lg:mt-0;
    .card {
        @apply h-full w-full max-w-lg rounded-xl border bg-white p-3 text-white md:p-10;

        h1 {
            @apply my-5 text-4xl font-semibold;
        }
        p {
            @apply text-lg text-[#404F5E];
        }
        i {
            @apply ml-[-15px] text-[100px] leading-[200px];
        }
    }
}
</style>
